import { AfterViewChecked, Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { CreateOrEditMedicationReadingDto, GetEMarForViewDto, MedicationReadingsServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { outputs } from '@syncfusion/ej2-angular-calendars/src/calendar/calendar.component';

@Component({
    selector: 'add-readings-dialog',
    templateUrl: './add-readings-dialog.component.html',
    styleUrls: ['add-readings-dialog.component.css']
})
export class AddReadingsDialogComponent extends AppComponentBase {

    @ViewChild('addReadingsModal', { static: true}) modal: ModalDirective;
    @ViewChild('addReadingsForm', { static: true }) form: NgForm;
    @Output() saveReading: EventEmitter <any> =new EventEmitter<any> ();
    active = false;
    saving = false;
    patientsName:string;
    medicationName: string;
    readVitals = false;
    readSuger =false;
    readWeight =false;
    _required =false;
    date: Date;
    medicationScheduleId;
    clientMedicationId;
    patientId; 
    patientEMr = new GetEMarForViewDto();
    medicationReadingsSaved : boolean;
    medReadings
    tempEmarId:any;
    hideDiv=false
    view=false;
    temperatureDenied = false;
    bloodPressureDenied = false;
    pulseRateDenied = false;
    respirationRateDenied = false;
    o2LevelDenied = false;
    weightDenied = false;
    bloodSugarDenied = false;
    MediactioReading: CreateOrEditMedicationReadingDto = new CreateOrEditMedicationReadingDto();// =  CreateOrEditMedicationReadingDto();
    constructor(injector: Injector,
        private _medicationReadingsServiceProxy: MedicationReadingsServiceProxy) {
        super(injector);
        this.date= new Date();
    }

    counter(i: number): any {
        return new Array(i);
    }

    show(data): void {
        //this.MediactioReading:CreateOrEditMedicationReadingDto = new CreateOrEditMedicationReadingDto();
        if(data){
            this.MediactioReading = new CreateOrEditMedicationReadingDto();
            this.MediactioReading = data;
        }
        if(!this.MediactioReading.eMarId){
            this.MediactioReading.eMarId = this.tempEmarId;
        }
        var sDate = new Date;
        this._medicationReadingsServiceProxy.getMedicationReadingForEdit(this.MediactioReading.eMarId, this.medicationScheduleId, moment(sDate))
            .subscribe(result => {
                if (result.medicationReading) {
                    this.MediactioReading = new CreateOrEditMedicationReadingDto();                   
                    this.MediactioReading = result.medicationReading;                   
                }
            });
        this._medicationReadingsServiceProxy.getMedicationReadingsHistory(this.clientMedicationId, this.patientId)
            .subscribe(result => {
                {
                    this.medReadings = result;
                }
            });

        this.modal.show();
    } 

    assigneValues(emar)
    {
        
       var MediactioReading = new CreateOrEditMedicationReadingDto();
        if (emar) {            
            this.patientEMr = new  GetEMarForViewDto();
            this.patientEMr = emar;
            this.patientsName = this.patientEMr.clientMedication.patientsname;
            this.readVitals = this.patientEMr.clientMedication.vitalsReading;
            this.readSuger = this.patientEMr.clientMedication.bloodSugarReading;
            this.patientId = this.patientEMr.clientMedication.patientId;
            this.clientMedicationId = this.patientEMr.clientMedication.clientMedication.id; 
            this.readWeight = this.patientEMr.clientMedication.weightReading;
            if(this.readVitals==true ||this.readSuger==true||this.readWeight==true ){
                this.hideDiv=true;
               
            }
            else{
                this.hideDiv=false;
            }
            this.medicationScheduleId = this.patientEMr.clientMedicationScheduleTime.id;
            this.medicationName = this.patientEMr.clientMedication.drugDescription + "(" + this.patientEMr.clientMedication.strength + " " + this.patientEMr.clientMedication.drug_strengthUnit + " " + this.patientEMr.clientMedication.drug_DoseForm + ")"  ;
            MediactioReading.eMarId = this.patientEMr.clientMedicationScheduleTime.emarId;
            this.tempEmarId = this.patientEMr.clientMedicationScheduleTime.emarId;
            this.show(MediactioReading);
        }

    }

   
    save() {
        this._required = true;
        this.MediactioReading.medicationScheduleTimeId = this.patientEMr.clientMedicationScheduleTime.id;
        this.MediactioReading.scheduleDate = moment(this.date);

    
        if ((this.readVitals == true
            && ((this.temperatureDenied == false && (this.MediactioReading.temperature == null || this.MediactioReading.temperature == undefined || this.MediactioReading.temperature == '')) ||
               (this.bloodPressureDenied == false && (this.MediactioReading.bloodPressure == null || this.MediactioReading.bloodPressure == undefined || this.MediactioReading.bloodPressure =='')) ||
               (this.pulseRateDenied == false  && (this.MediactioReading.pulseRate == null || this.MediactioReading.pulseRate == undefined || this.MediactioReading.pulseRate =='')) ||
               (this.respirationRateDenied == false && (this.MediactioReading.respirationRate == null || this.MediactioReading.respirationRate == undefined || this.MediactioReading.respirationRate =='')) ||
               (this.o2LevelDenied == false  && (this.MediactioReading.o2Level == null || this.MediactioReading.o2Level == undefined || this.MediactioReading.o2Level =='')))
            )
            ||
            (this.readWeight == true 
            && (this.weightDenied == false && (this.MediactioReading.weight == null || this.MediactioReading.weight == undefined|| this.MediactioReading.weight =='')))
            ||
            (this.readSuger == true
            && (this.bloodSugarDenied == false  && (this.MediactioReading.bloodSugar == null || this.MediactioReading.bloodSugar == undefined || this.MediactioReading.bloodSugar == ''))) )
            {
            return false;
        }
        else {
            this._medicationReadingsServiceProxy.createOrEdit(this.MediactioReading) 
                .subscribe(result => {
                    this.medicationReadingsSaved = true;
                    if (this.patientEMr.emrHistory.status == "Administerd") {
                        this.saveReading.emit(false);
                    } else {
                        this.saveReading.emit(this.medicationReadingsSaved);
                    }

                    this.close();
                    this.notify.info(this.l('Readings recorded successfully'));
                });
        }

    }

    close(): void {
        this.active = false;
        this.medicationReadingsSaved=false;
        this.modal.hide();
    }

    bloodSugerChecked(event: any)
    {
        if(event.currentTarget.checked)
        {    
            this.bloodSugarDenied = true; 
            this.MediactioReading.bloodSugar ="";       
        }
        else
        {          
            this.bloodSugarDenied = false;
        }
    }
    temperatureDeniedChecked(event: any)
    {
        if(event.currentTarget.checked)
        {    
            this.temperatureDenied = true; 
            this.MediactioReading.temperature ="";       
        }
        else
        {          
            this.temperatureDenied = false;
        }
    }
    pulseRateDeniedChecked(event: any)
    {
        if(event.currentTarget.checked)
        {    
            this.pulseRateDenied = true;
            this.MediactioReading.pulseRate ="";        
        }
        else
        {          
            this.pulseRateDenied = false;
        }
    }
    respirationRateDeniedChecked(event: any)
    {
        if(event.currentTarget.checked)
        {    
            this.respirationRateDenied = true;
            this.MediactioReading.respirationRate ="";        
        }
        else
        {          
            this.respirationRateDenied = false;
        }
    } 
    bloodPressureDeniedChecked(event: any)
    {
        if(event.currentTarget.checked)
        {    
            this.bloodPressureDenied = true;
            this.MediactioReading.bloodPressure ="";        
        }
        else
        {          
            this.bloodPressureDenied = false;
        }
    }
    weightDeniedChecked(event: any)
    {
        if(event.currentTarget.checked)
        {    
            this.weightDenied = true;  
            this.MediactioReading.weight ="";      
        }
        else
        {          
            this.weightDenied = false;
        }
    }
    o2LevelDeniedChecked(event: any)
    {
        if(event.currentTarget.checked)
        {    
            this.o2LevelDenied = true; 
            this.MediactioReading.o2Level ="";       
        }
        else
        {          
            this.o2LevelDenied = false;
        }
    }
    

    
}
