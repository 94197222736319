import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.ClientAppointmentsServiceProxy,
        ApiServiceProxies.CustomeAuditHistoriesServiceProxy,
        ApiServiceProxies.EMARRecordsServiceProxy,
        ApiServiceProxies.ClientProfileStatusInfosServiceProxy, 
        ApiServiceProxies.HoldScheduleTimesServiceProxy,
        ApiServiceProxies.DiscontinuedMedDocUploadsServiceProxy  ,
        ApiServiceProxies.DiscontinuedMedsServiceProxy ,
        ApiServiceProxies.EventSignUpNewServiceProxy,
        ApiServiceProxies.MedicatioNotesServiceProxy,
        ApiServiceProxies.MedicationReadingsServiceProxy,
        ApiServiceProxies.OmmisionsServiceProxy,
        ApiServiceProxies.MedicationErrorsServiceProxy,
        ApiServiceProxies.HoldMedicationsServiceProxy,
        ApiServiceProxies.EMarsServiceProxy,
        ApiServiceProxies.Client_Medication_Schedule_TimesServiceProxy,        
        ApiServiceProxies.Client_Medication_SchedulesServiceProxy,        
        ApiServiceProxies.MedNotesServiceProxy,
        ApiServiceProxies.ClientMedicationsServiceProxy,
        ApiServiceProxies.ProvidersMastersServiceProxy,        
        ApiServiceProxies.PracticeGroupMastersServiceProxy,        
        ApiServiceProxies.Drug_MastersServiceProxy,        
        ApiServiceProxies.ClientDashboardIncidentServiceProxy,
        ApiServiceProxies.ClientDashboardDocumentServiceProxy,
        ApiServiceProxies.ClientDashboardDailyLogServiceProxy,
        ApiServiceProxies.ClientDashboardFacesheetServiceProxy,
        ApiServiceProxies.TargatedBehaviorsServiceProxy,        
        ApiServiceProxies.BehaviorsMastersServiceProxy,        
        ApiServiceProxies.BehaviorsCategoryMastersServiceProxy,        
        ApiServiceProxies.ClientDashboardNursingCareServiceProxy,
        ApiServiceProxies.DocumentTypeWorkFlowServiceProxy,
        ApiServiceProxies.ClientDashboardNursingAssessmentServiceProxy,
        ApiServiceProxies.SiteDocumentServiceProxy,
        ApiServiceProxies.PatientDetailsesServiceProxy,
        ApiServiceProxies.RefDocumentuploadsServiceProxy,        
        ApiServiceProxies.ReferralStaffSupportsServiceProxy,        
        ApiServiceProxies.ReferralServicesServiceProxy,        
        ApiServiceProxies.AllergiesServiceProxy,        
        ApiServiceProxies.ReferralsServiceProxy,        
        ApiServiceProxies.ReferralContactsServiceProxy,        
        ApiServiceProxies.GeneralSpecialSupportNeedsServiceProxy,        
        ApiServiceProxies.MonthlyIncomesServiceProxy,        
        ApiServiceProxies.InsurancesServiceProxy,        
        ApiServiceProxies.PCPBPEditServiceProxy,
        ApiServiceProxies.FacilityReportsEditServiceProxy,
        ApiServiceProxies.PatientsEditServiceProxy,
        ApiServiceProxies.FireDrillsEditServiceProxy,
        ApiServiceProxies.IncidentReportsEditServiceProxy,
        ApiServiceProxies.NursingAssessmentEditServiceProxy,
        ApiServiceProxies.NursingCarePlansEditServiceProxy,
        ApiServiceProxies.FacilityReportsCopyAppServieServiceProxy,
        ApiServiceProxies.FireDrillsCopyServiceProxy,
        ApiServiceProxies.PcpbpCopyServiceProxy,
        ApiServiceProxies.NursingAssessmentsCopyServiceProxy,
        ApiServiceProxies.NursingCarePlansCopyServiceProxy,
        ApiServiceProxies.IncidentReportCopyServiceProxy,
        ApiServiceProxies.NcpTrainedStaffsServiceProxy,        
        ApiServiceProxies.FireDrillSettingsServiceProxy,
        ApiServiceProxies.DisinfectLogSettingsServiceProxy,
        ApiServiceProxies.HealthCareProvidersServiceProxy,
        ApiServiceProxies.TransportationLogSettingsServiceProxy,
        ApiServiceProxies.TemperatureLogSettingsServiceProxy,
        ApiServiceProxies.SeizureLogSettingsServiceProxy,
        ApiServiceProxies.GoalLogSettingsServiceProxy,
        ApiServiceProxies.HealthLogSettingsServiceProxy,
        ApiServiceProxies.FamilyMemberLogSettingsServiceProxy,
        ApiServiceProxies.DailyLogSettingsServiceProxy,
        ApiServiceProxies.BowelMovementSettingsServiceProxy,
        ApiServiceProxies.BloodSugarLogSettingsServiceProxy,
        ApiServiceProxies.BehaviorLogSettingsServiceProxy,
        ApiServiceProxies.DisinfectDashboardServiceProxy,
        ApiServiceProxies.BloodSugarDashboardServiceProxy,
        ApiServiceProxies.NursingDashboardServiceProxy,
        ApiServiceProxies.NursingCareSettingsServiceProxy,
        ApiServiceProxies.AcknowledgementServiceProxy,
        ApiServiceProxies.TrainingDashboardServiceProxy ,
        ApiServiceProxies.EventSignUpServiceProxy,
        ApiServiceProxies.PcbBpSettingsServiceProxy,
        ApiServiceProxies.IncidentReportSettingsServiceProxy,
        ApiServiceProxies.NursingLogSettingsServiceProxy ,
        ApiServiceProxies.CategoriesServiceProxy,        
        ApiServiceProxies.HealthDashboardServiceProxy,
        ApiServiceProxies.UserDepartmentsServiceProxy,        
        ApiServiceProxies.HealthLogsServiceProxy,
        ApiServiceProxies.NursingAssessmentSettingsServiceServiceProxy,
        ApiServiceProxies.CommonUsersServiceProxy,
        ApiServiceProxies.NursingLogsServiceProxy,
        ApiServiceProxies.DisinfectLogsServiceProxy,
        ApiServiceProxies.BloodSugarLogsServiceProxy,
        ApiServiceProxies.NewProgramMastersServiceProxy,        
        ApiServiceProxies.FacilityManagementDashboardServiceProxy,
        ApiServiceProxies.FiredrillDashboardServiceProxy,   
        ApiServiceProxies.BehaviorDashboardServiceProxy,
        ApiServiceProxies.DiaryDashboardServiceProxy,
        ApiServiceProxies.BowelDashBoardServiceProxy,
        ApiServiceProxies.DailyDashboardServiceProxy,
        ApiServiceProxies.FamilyDashboardServiceProxy,
        ApiServiceProxies.GoalDashboardServiceProxy,
        ApiServiceProxies.SiezureDashboardServiceProxy,

        ApiServiceProxies.TemperatureDashboardServiceProxy,
        ApiServiceProxies.TransportDashboardServiceProxy,
        ApiServiceProxies.SiezureDashboardServiceProxy,
        ApiServiceProxies.PCPDashboardServiceProxy,

        ApiServiceProxies.EventParticipantsServiceProxy,        
        ApiServiceProxies.CalendersServiceProxy,        
       
        ApiServiceProxies.TemparatureLogsServiceProxy,        
        ApiServiceProxies.TransportationLogsServiceProxy,        
        ApiServiceProxies.FamilyMemberLogsServiceProxy,        
        ApiServiceProxies.GoalLogsServiceProxy,        
        ApiServiceProxies.BehaviorLogsServiceProxy,        
        ApiServiceProxies.DailyLogsServiceProxy,        
        ApiServiceProxies.CommonClientServiceProxy,
        ApiServiceProxies.BowelMovementsServiceProxy,        
        ApiServiceProxies.SeizureLogsServiceProxy,        
        ApiServiceProxies.AnnouncementsServiceProxy,        
        ApiServiceProxies.NursingAssessmentDashboardServiceProxy,
        ApiServiceProxies.NursingCareDashboardServiceProxy,
        ApiServiceProxies.IncidentDashboardServiceProxy,
        ApiServiceProxies.ClientDashboardServiceProxy,
        ApiServiceProxies.GraphApiServiceProxy,

        ApiServiceProxies.CommonMastersServiceProxy,    
        ApiServiceProxies.TitleMastersServiceProxy,        
        ApiServiceProxies.PCPRatioScopeServiceProxy,
        ApiServiceProxies.PCBsServiceProxy,
        ApiServiceProxies.UserAdditionalInfosServiceProxy,        
        ApiServiceProxies.PinnedDashboardServiceProxy,
        ApiServiceProxies.CasemgntAssociatedStaffsServiceProxy,
        ApiServiceProxies.CourseDashboardServiceProxy,
        ApiServiceProxies.MappedProgramServiceProxy,
        ApiServiceProxies.SiteAssociatedStaffsServiceProxy,
        ApiServiceProxies.CertificateUploadsServiceProxy,
        ApiServiceProxies.CoursesServiceProxy,
        //ApiServiceProxies.MedAdminDashboardServiceProxy,
        ApiServiceProxies.BehDiaryNotesServiceProxy,
        ApiServiceProxies.BehavioralDiariesServiceProxy,
        ApiServiceProxies.VocationalgroupsServiceProxy,
        ApiServiceProxies.FacilityReportsServiceProxy,

        ApiServiceProxies.MedAdminScreeningToolRecordsServiceProxy,
        ApiServiceProxies.MedAdminScreeningToolsServiceProxy,
        ApiServiceProxies.MedAdminScreeningToolsServiceProxy,
        //ApiServiceProxies.MedAdminScreeningMastersServiceProxy,
        ApiServiceProxies.FireDrillsServiceProxy,
        ApiServiceProxies.ReportDownloadInfosServiceProxy,
        ApiServiceProxies.ClientDiaryNotesServiceProxy,
        ApiServiceProxies.NurAssLabworkDocumentUploadsServiceProxy,
        ApiServiceProxies.ClientDiariesServiceProxy,
        ApiServiceProxies.IncRepCommentsServiceProxy,
        ApiServiceProxies.IncRepDocumentUploadsServiceProxy,
        ApiServiceProxies.IncRepNotificationsServiceProxy,
        ApiServiceProxies.IncRepWitnessesServiceProxy,
        ApiServiceProxies.IncidentReportsServiceProxy,
        ApiServiceProxies.NutDiaryNotesServiceProxy,
        ApiServiceProxies.NutritionDiariesServiceProxy,
        ApiServiceProxies.SiginingSheetsServiceProxy,
        ApiServiceProxies.StandingCommiteeDocsServiceProxy,
        ApiServiceProxies.MeetingApprovalsServiceProxy,
        ApiServiceProxies.NutAssDocumentUploadsServiceProxy,
        ApiServiceProxies.StandingCommiteeMeetingsServiceProxy,
        ApiServiceProxies.BSISsServiceProxy,
        ApiServiceProxies.NutritionAssLabDetailsesServiceProxy,
        ApiServiceProxies.NcpReviewTrackingsServiceProxy,
        ApiServiceProxies.NcpReportReviewsServiceProxy,
        ApiServiceProxies.HRSTReviewTrackingsServiceProxy,
        ApiServiceProxies.HRSTReportReviewsServiceProxy,
        ApiServiceProxies.NurDiaryReviewTrackingsServiceProxy,
        ApiServiceProxies.NurDiaryReportReviewsServiceProxy,
        ApiServiceProxies.NurRepTeamReviewTrackingsServiceProxy,
        ApiServiceProxies.NurRepTeamtReportReviewsServiceProxy,
        ApiServiceProxies.NurAssReviewTrackingsServiceProxy,
        ApiServiceProxies.NurAssReviewsServiceProxy,
        ApiServiceProxies.CPdocsServiceProxy,
        ApiServiceProxies.NurDiaryNotesServiceProxy,
        ApiServiceProxies.NursingDiariesServiceProxy,
        ApiServiceProxies.NutAssProblemListsServiceProxy,
        ApiServiceProxies.NutAssLabsServiceProxy,
        ApiServiceProxies.NutAssAnthropometricInfosServiceProxy,
        ApiServiceProxies.NutAssNutritionActivitiesServiceProxy,
        ApiServiceProxies.NutAssAppointmentsServiceProxy,
        ApiServiceProxies.NCPRecordsServiceProxy,
        ApiServiceProxies.IstAttendedStaffsServiceProxy,
        ApiServiceProxies.NutAssWtHistoriesServiceProxy,
        ApiServiceProxies.NursingNutritionsServiceProxy,
        ApiServiceProxies.IstAcknowledgementsServiceProxy,
        ApiServiceProxies.IndividualSpecificTrainingsServiceProxy,
        ApiServiceProxies.NurseReportToTeamsServiceProxy,
        ApiServiceProxies.HRSTNursingNeedsServiceProxy,
        ApiServiceProxies.CRSTRecommendationsServiceProxy,
        ApiServiceProxies.PertinentHistoriesServiceProxy,
        ApiServiceProxies.CRSTSwallowingProbsServiceProxy,
        ApiServiceProxies.CRSTProbwithChewingsServiceProxy,
        ApiServiceProxies.CRSTHighRiskDiagnosisesServiceProxy,
        ApiServiceProxies.ChokingRiskToolsServiceProxy,
        ApiServiceProxies.NurAssAcknowledgementsServiceProxy,
        ApiServiceProxies.NurAssReviewSystemAreasServiceProxy,
        ApiServiceProxies.NurAssImmunizationDatesServiceProxy,
        ApiServiceProxies.NurAssFollowupsServiceProxy,
        ApiServiceProxies.NurAssLabworksServiceProxy,
        ApiServiceProxies.NurAssDiagnosisesServiceProxy,
        ApiServiceProxies.NurAssAllergiesServiceProxy,
        ApiServiceProxies.NurAssVitalSignsServiceProxy,
        ApiServiceProxies.NurAsStaffSupervisionsServiceProxy,
        ApiServiceProxies.NurAssStaffRecommendationsServiceProxy,
        ApiServiceProxies.NCAcknowledgmentsServiceProxy,
        ApiServiceProxies.NursingAssessmentsServiceProxy,
        ApiServiceProxies.NurAssAddAssessmentsServiceProxy,
        ApiServiceProxies.TicketDocumentUploadsServiceProxy,
        ApiServiceProxies.RequestTicketsServiceProxy,
        ApiServiceProxies.DocumentUploadsServiceProxy,       
        ApiServiceProxies.DocumenttypesServiceProxy,
        ApiServiceProxies.ProgramEnrollmentsServiceProxy,
        ApiServiceProxies.ProgressNotesServiceProxy,
        ApiServiceProxies.TrainedStaffsServiceProxy,
        ApiServiceProxies.RiskAreasServiceProxy,
        ApiServiceProxies.NursingCarePlansServiceProxy,
        ApiServiceProxies.ProgramsServiceProxy,
        ApiServiceProxies.SitesServiceProxy,
        
        ApiServiceProxies.BehavioralConsultationsServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.ABCsServiceProxy,
        ApiServiceProxies.UserAddressServiceProxy,
        ApiServiceProxies.InsuranceDetailsServiceProxy,
        ApiServiceProxies.PatientContactsServiceProxy,
        ApiServiceProxies.PatientsServiceProxy,  

        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
